import { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import RateReviewRoundedIcon from "@material-ui/icons/RateReviewRounded";
import SquareFootRoundedIcon from "@material-ui/icons/SquareFootRounded";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSolarPanel,
  faFileSignature,
  faFolderOpen,
  faBuilding,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";

import { TaskDashGenData } from "./TaskDashGenData";
import { SaleDashChecklist } from "./SaleDashChecklist";
import { SaleDashRejectReasons } from "./SaleDashRejectReasons";
import { SaleDashNotes } from "./SaleDashNotes";
import { SaleDashFiles } from "./SaleDashFiles";
import { PropDashUcs } from "../../proposal/components/PropDashUcs";
import { PropDashKit } from "../../proposal/components/PropDashKit";
import { PropDashProject } from "../../proposal/components/PropDashProject";
import { PropDashPricing } from "../../proposal/components/PropDashPricing";
import { SaleDashFinancings } from "./SaleDashFinancings";
import { SaleDashContracts } from "./SaleDashContracts";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import {
  AuthContextProps,
  KanbanFilledData,
  NoteData,
  ProposalData,
  SettingsContextProps,
  NotePopCustData,
  CustomerData,
} from "../../shared/data/types";

import "./SaleDash.scss";
import { ChatsOmnichannel } from "../../shared/components/UIElements/ChatsOmnichannel";

interface TaskDashProps {
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  active: boolean;
  closeHandler: () => void;
  kanbanData: KanbanFilledData;
  loadedItem: KanbanFilledData["items"][0];
  activeNote?: string;
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setScheduleData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  fetchId: string;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
  isManagerView?: boolean;
  isTeamLoaded?: boolean;
}

export const TaskDash = (props: TaskDashProps) => {
  const {
    active,
    closeHandler,
    loadedItem,
    auth,
    setLoadedData,
    setActiveItem,
    fetchId,
    kanbanData,
    settingsCtx,
    type,
    activeNote = "",
    setScheduleData,
    setUpdateColumnsTotals,
    isManagerView = false,
    isTeamLoaded = false,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isSmallWidth, setIsSmallWidth] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [memory, setMemory] = useState<KanbanFilledData["items"][0]>(null);
  const ip = settingsCtx?.individualPermissions?.find((ip) => {
    return ip?.user?.toString() === auth?.userId;
  });
  // const isManager =
  //   (type === "SALES" && ip?.salesManager) ||
  //   (type === "PROJECT" && ip?.projectManager) ||
  //   (type === "INSTALLATION" && ip?.installationManager) ||
  //   (type === "AFTER_SALES" && ip?.afterSalesManager) ||
  //   false;
  // const canAccessPropList =
  //   settingsCtx.individualPermissions
  //     ?.filter((ip) => {
  //       return ip.proposalsList;
  //     })
  //     ?.map((ip) => {
  //       return ip?.user;
  //     })
  //     .includes(auth.userId) || auth.type !== "VENDOR";
  const hasPriceEditPerm =
    auth.type === "MANAGER" ||
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip.user === auth.userId;
    })?.pricingEdit;
  const canAccessPricingTab =
    settingsCtx.individualPermissions
      ?.filter((ip) => {
        return ip.pricingTab;
      })
      ?.map((ip) => {
        return ip?.user;
      })
      .includes(auth.userId) ||
    auth.type !== "VENDOR" ||
    settingsCtx.permissions?.vendorPriceEdit ||
    hasPriceEditPerm;

  useEffect(() => {
    if (!!loadedItem) {
      setMemory(loadedItem);
    }
  }, [loadedItem, loadedItem?.approvedProposal]);

  const resizeHandler = () => {
    if (window.innerWidth < 400) {
      setIsSmallWidth(true);
    } else {
      setIsSmallWidth(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    var timer = setTimeout(() => {
      resizeHandler();
    }, 1000);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      clearTimeout(timer);
    };
  }, []);

  const changeTabHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={`sale-dash ${active ? "sale-dash--active" : ""}`}>
      <ModalError error={error} onClear={clearError} />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => {
          if (active) {
            closeHandler();
          }
        }}
      >
        <div className="sale-dash__content">
          {isLoading && <LoadingSpinnerOverlayRegular image />}
          <div className="sale-dash__controllers">
            <IconButton
              size="small"
              title="Opções"
              className={`table__item-action`}
              onClick={() => {
                if (active) {
                  closeHandler();
                }
              }}
            >
              <CloseRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          </div>
          <div className="sale-dash__col-left">
            <TaskDashGenData
              auth={auth}
              fetchId={fetchId}
              memory={memory}
              setLoadedData={setLoadedData}
              sendRequest={sendRequest}
              kanbanData={kanbanData}
              setActiveItem={setActiveItem}
              settingsCtx={settingsCtx}
              type={type}
              setUpdateColumnsTotals={setUpdateColumnsTotals}
              isManagerView={isManagerView}
              isTeamLoaded={isTeamLoaded}
            />
            {auth.signupPlan === "ENTERPRISE" && !!memory && (
              <SaleDashChecklist
                auth={auth}
                type={type}
                saleId={memory?._id}
                setLoadedData={setLoadedData}
              />
            )}
            {auth.signupPlan === "ENTERPRISE" &&
              type === "PROJECT" &&
              !!memory && (
                <SaleDashRejectReasons
                  auth={auth}
                  loadedItem={memory}
                  setLoadedData={setLoadedData}
                />
              )}
          </div>
          <div className="sale-dash__col-right">
            <div className="sale-dash__col-right-content">
              <Tabs
                className="sale-dash__col-right-tabs"
                value={tab}
                onChange={changeTabHandler}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  style={{ minWidth: "10px" }}
                  title="Notas"
                  icon={<RateReviewRoundedIcon />}
                />
                <Tab
                  style={{ minWidth: "10px" }}
                  title="Arquivos"
                  icon={
                    <FontAwesomeIcon
                      icon={faFolderOpen}
                      style={{ fontSize: "20px" }}
                    />
                  }
                  disabled={auth.signupPlan !== "ENTERPRISE"}
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    display: isSmallWidth ? "none" : "inherit",
                  }}
                  title="UCs"
                  icon={
                    <FontAwesomeIcon
                      icon={faBuilding}
                      style={{ fontSize: "20px" }}
                    />
                  }
                  disabled={auth.signupPlan !== "ENTERPRISE"}
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    display: isSmallWidth ? "none" : "inherit",
                  }}
                  title="Kit"
                  icon={
                    <FontAwesomeIcon
                      icon={faSolarPanel}
                      style={{
                        fontSize: "1.15rem",
                      }}
                    />
                  }
                  disabled={auth.signupPlan !== "ENTERPRISE"}
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    display: type === "ADMINISTRATIVE" ? "none" : "inherit",
                  }}
                  title="Projeto"
                  icon={<SquareFootRoundedIcon />}
                  disabled={
                    auth.signupPlan !== "ENTERPRISE" ||
                    type === "ADMINISTRATIVE"
                  }
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    display: type !== "ADMINISTRATIVE" ? "none" : "inherit",
                  }}
                  title="Contratos"
                  icon={
                    <FontAwesomeIcon
                      icon={faFileSignature}
                      style={{ fontSize: "20px" }}
                    />
                  }
                  disabled={
                    auth.signupPlan !== "ENTERPRISE" ||
                    type !== "ADMINISTRATIVE"
                  }
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    display:
                      type !== "ADMINISTRATIVE" || auth.whitelabel === "SERRANA"
                        ? "none"
                        : "inherit",
                    // display: "none",
                  }}
                  title="Financiamentos"
                  icon={
                    <FontAwesomeIcon
                      icon={faMoneyBillWave}
                      style={{ fontSize: "20px", color: "#009688" }}
                    />
                  }
                  disabled={
                    auth.signupPlan !== "ENTERPRISE" ||
                    type !== "ADMINISTRATIVE"
                  }
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    display: !canAccessPricingTab ? "none" : "inherit",
                  }}
                  title="Precificação"
                  icon={<AttachMoneyIcon />}
                  disabled={
                    auth.signupPlan !== "ENTERPRISE" || !canAccessPricingTab
                  }
                />
                <Tab
                  style={{
                    minWidth: "10px",
                    // display: auth.type === "VENDOR" && !isManager ? "none" : "inherit",
                  }}
                  title="Conversas"
                  icon={<WhatsAppIcon />}
                  // disabled={auth.type === "VENDOR" && !isManager}
                />
              </Tabs>
              {tab === 0 && (
                <SaleDashNotes
                  settingsCtx={settingsCtx}
                  auth={auth}
                  itemId={memory?._id}
                  activeNote={activeNote}
                  type={type}
                  customerId={memory?.customer?.id}
                  setScheduleData={setScheduleData}
                />
              )}
              {tab === 1 && <SaleDashFiles cid={memory?.customer?.id} />}
              {tab === 2 && (
                <PropDashUcs
                  memory={memory?.approvedProposal as ProposalData}
                  auth={auth}
                />
              )}
              {tab === 3 && (
                <PropDashKit
                  memory={memory?.approvedProposal as ProposalData}
                  auth={auth}
                />
              )}
              {tab === 4 && type !== "ADMINISTRATIVE" && (
                <PropDashProject
                  memory={memory?.approvedProposal as ProposalData}
                  auth={auth}
                />
              )}
              {tab === 5 && type === "ADMINISTRATIVE" && (
                <SaleDashContracts
                  contracts={memory?.contracts}
                  auth={auth}
                  itemId={memory?._id}
                  item={memory}
                  setKanbanData={setLoadedData}
                />
              )}
              {tab === 6 && type === "ADMINISTRATIVE" && (
                <SaleDashFinancings
                  auth={auth}
                  settingsCtx={settingsCtx}
                  customerId={memory?.customer?.id}
                  type={memory?.type}
                  customer={memory?.customer}
                />
              )}
              {tab === 7 && canAccessPricingTab && (
                <PropDashPricing
                  auth={auth}
                  memory={memory?.approvedProposal as ProposalData}
                />
              )}
              {tab === 8 && (
                <ChatsOmnichannel
                  auth={auth}
                  customer={memory.customer as unknown as CustomerData}
                  settingsCtx={settingsCtx}
                />
              )}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
