import { Fragment, useEffect, useState } from "react";

import { useForm } from "../../../shared/hooks/formHook";
import { HomeDashboardUser } from "./HomeDashboardUser";
import { HomeDashboardFinancings } from "./HomeDashboardFinancings";
import { HomeDashboardGenSales } from "./HomeDashboardGenSales";
import { HomeDashboardClosedSales } from "./HomeDashboardClosedSales";
import { HomeDashboardConvRates } from "./HomeDashboardConvRates";
import { HomeDashboardSalesInProgress } from "./HomeDashboardSalesInProgress";
import { HomeDashboardSalesActivity } from "./HomeDashboardSalesActivity";
import { HomeDashboardViewedPropsSales } from "./HomeDashboardViewedPropsSales";
import { HomeDashboardSalesAmounts } from "./HomeDashboardSalesAmounts";
import { HomeDashboardNextSchedules } from "./HomeDashboardNextSchedules";
import { HomeDashboardSoldPower } from "./HomeDashboardSoldPower";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextProps,
  NotePopCustData,
} from "../../../shared/data/types";

interface HomeDashboardProps {
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
}

export const HomeDashboard = (props: HomeDashboardProps) => {
  const { auth, settingsCtx } = props;
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const isSalesManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.salesManager || false;
  const [fetchId, setFetchId] = useState<string>(
    auth.type !== "VENDOR" || isSalesManager ? auth.managerId : auth.userId
  );
  const [isLoadingGenSales, setIsLoadingGenSales] = useState<boolean>(false);
  const [isLoadingClosedSales, setIsLoadingClosedSales] =
    useState<boolean>(false);
  const [loadedGenSales, setLoadedGenSales] = useState<
    {
      label: string;
      val: number;
      sales: number;
      delta: number;
      deltaIsPositive: boolean;
    }[]
  >([
    {
      label: "",
      val: 0,
      sales: 0,
      delta: 0,
      deltaIsPositive: true,
    },
  ]);
  const [loadedClosedSales, setLoadedClosedSales] = useState<
    {
      label: string;
      val: number;
      sales: number;
      power: number;
      delta: number;
      deltaIsPositive: boolean;
    }[]
  >([
    {
      label: "",
      val: 0,
      sales: 0,
      power: 0,
      delta: 0,
      deltaIsPositive: true,
    },
  ]);
  const [loadedSalesInProgress, setLoadedSalesInProgress] = useState<{
    totalSales: number;
    totalAmount: number;
    totalPotProfit: number;
  }>({ totalSales: 0, totalAmount: 0, totalPotProfit: 0 });
  const [loadedLastViewedPropsSales, setLoadedLastViewedPropsSales] =
    useState<KanbanFilledData>(null);
  const [loadedNextSchedules, setLoadedNextSchedules] = useState<
    NotePopCustData[]
  >([]);

  useEffect(() => {
    if (formState?.inputs?.user?.value) {
      setFetchId(formState?.inputs?.user?.value as string);
    }
  }, [formState?.inputs?.user?.value]);

  return (
    <Fragment>
      <HomeDashboardUser
        inputHandler={inputHandler}
        settingsCtx={settingsCtx}
        auth={auth}
        formState={formState}
        fetchId={fetchId}
      />
      {auth.whitelabel !== "SERRANA" && (
        <HomeDashboardFinancings
          settingsCtx={settingsCtx}
          auth={auth}
          fetchId={fetchId}
        />
      )}
      <HomeDashboardGenSales
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedGenSales}
        setLoadedData={setLoadedGenSales}
        setIsLoadingGenSales={setIsLoadingGenSales}
      />
      <HomeDashboardClosedSales
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedClosedSales}
        setLoadedData={setLoadedClosedSales}
        setIsLoadingClosedSales={setIsLoadingClosedSales}
      />
      <HomeDashboardConvRates
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        genSales={
          loadedGenSales?.map((gs) => {
            return gs?.sales || 0;
          }) || [0, 0, 0]
        }
        closedSales={
          loadedClosedSales?.map((cs) => {
            return cs?.sales || 0;
          }) || [0, 0, 0]
        }
        isLoadingGenSales={isLoadingGenSales}
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardViewedPropsSales
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedLastViewedPropsSales}
        setLoadedData={setLoadedLastViewedPropsSales}
      />
      <HomeDashboardSalesActivity
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        genSales={
          loadedGenSales?.map((gs) => {
            return gs?.sales || 0;
          }) || [0, 0, 0]
        }
        closedSales={
          loadedClosedSales?.map((cs) => {
            return cs?.sales || 0;
          }) || [0, 0, 0]
        }
        isLoadingGenSales={isLoadingGenSales}
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardSalesInProgress
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedSalesInProgress}
        setLoadedData={setLoadedSalesInProgress}
        setIsLoadingClosedSales={setIsLoadingClosedSales}
      />
      <HomeDashboardSalesAmounts
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        closedSalesAmounts={
          loadedClosedSales?.map((cs) => {
            return cs?.val || 0;
          }) || [0, 0, 0]
        }
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardSoldPower
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedClosedSales}
        isLoadingClosedSales={isLoadingClosedSales}
      />
      <HomeDashboardNextSchedules
        settingsCtx={settingsCtx}
        auth={auth}
        fetchId={fetchId}
        loadedData={loadedNextSchedules}
        setLoadedData={setLoadedNextSchedules}
      />
    </Fragment>
  );
};
