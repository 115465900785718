import { IDBPDatabase } from "idb";

import {
  AuthContextProps,
  CustomerData,
  FormHookDispState, TimePeriodData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// REACTIVATE CUSTOMERS ///////////////////////////
///////////////////////////////////////////////////
interface ReactivateCustomerProps extends FetchProps {
  customerId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
}

export const reactivateCustomer = async (props: ReactivateCustomerProps) => {
  const { sendRequest, auth, setLoadedData, customerId } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/single/archived-false/${customerId}`;
    await sendRequest(apiUrl, "PATCH", null, {
      Authorization: "Bearer " + auth.token,
    });
    setLoadedData((prevValues) => {
      return prevValues.filter((prevValue) => {
        return prevValue.id !== customerId;
      });
    });
  } catch (err) {}
};

// ARCHIVE CUSTOMERS //////////////////////////////
///////////////////////////////////////////////////
interface ArchiveCustomerProps extends FetchProps {
  customerId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
}

export const archiveCustomer = async (props: ArchiveCustomerProps) => {
  const { sendRequest, auth, setLoadedData, customerId } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/single/archived-true/${customerId}`;
    await sendRequest(apiUrl, "PATCH", null, {
      Authorization: "Bearer " + auth.token,
    });
    setLoadedData((prevValues) => {
      return prevValues.filter((prevValue) => {
        return prevValue.id !== customerId;
      });
    });
  } catch (err) {}
};

// ARCHIVE SELECTED CUSTOMERS /////////////////////
///////////////////////////////////////////////////
interface ArchiveSelectedCustomerProps extends FetchProps {
  customersIds: string[];
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
}

export const archiveSelectedCustomer = async (
  props: ArchiveSelectedCustomerProps
) => {
  const { sendRequest, auth, setLoadedData, customersIds } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/selected/archived-true`;
    await sendRequest(apiUrl, "PUT", JSON.stringify({ customersIds }), {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    });
    setLoadedData((prevValues) => {
      return prevValues.filter((prevValue) => {
        return !customersIds.includes(prevValue.id);
      });
    });
  } catch (err) {}
};

// REACTIVATE SELECTED CUSTOMERS //////////////////
///////////////////////////////////////////////////
interface ReactivateSelectedCustomerProps extends FetchProps {
  customersIds: string[];
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
}

export const reactivateSelectedCustomer = async (
  props: ReactivateSelectedCustomerProps
) => {
  const { sendRequest, auth, setLoadedData, customersIds } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/selected/archived-false`;
    await sendRequest(apiUrl, "PUT", JSON.stringify({ customersIds }), {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    });
    setLoadedData((prevValues) => {
      return prevValues.filter((prevValue) => {
        return !customersIds.includes(prevValue.id);
      });
    });
  } catch (err) {}
};

interface FetchCustomersProps extends FetchProps {
  setNetworkDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const fetchCustomers = async (props: FetchCustomersProps) => {
  const {
    sendRequest,
    auth,
    setNetworkDataReceived,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/paginated/${auth.userId}/${multiplier}`;
    const responseData: { customers: CustomerData[]; hasMoreItems: boolean } =
      await sendRequest(apiUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.customers];
      } else {
        return responseData.customers;
      }
    });
    setNetworkDataReceived(true);
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

interface FetchCustomersFromIDBProps {
  networkDataReceived: boolean;
  openIndexedDBHandlerComp: () => Promise<IDBPDatabase<unknown>>;
  readAllDataInIdb: (idb: IDBPDatabase<unknown>, st: string) => Promise<any[]>;
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
}

export const fetchCustomersFromIDB = async (
  props: FetchCustomersFromIDBProps
) => {
  const {
    networkDataReceived,
    openIndexedDBHandlerComp,
    readAllDataInIdb,
    setLoadedData,
  } = props;

  const idb = await openIndexedDBHandlerComp();
  const storedData: {
    customers: CustomerData[];
  }[] = await readAllDataInIdb(idb, "customers");
  if (
    !networkDataReceived &&
    storedData &&
    storedData.length > 0 &&
    storedData[0]?.customers?.length > 0 &&
    storedData[0].customers?.[0]?.registryDate
  ) {
    setLoadedData(storedData[0].customers);
  }
};

export const exportCustomerssData = async (props: FetchProps) => {
  const { sendRequest, auth } = props;

  try {
    const responseData: { link: string } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/customers/csv-data/${auth.userId}`,
      "POST",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    window.open(responseData.link, "_blank").focus();
  } catch (err) {}
};

// GET FILTERED CUSTOMERS /////////////////////////
///////////////////////////////////////////////////
interface GetFilteredCustomersProps extends FetchProps {
  setLoadedItems: React.Dispatch<React.SetStateAction<CustomerData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  filter1Active: boolean;
  filter2Active: boolean;
  filter1Value: "ACTIVE" | "ARCHIVED";
  filter2Value: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  fetchId: string;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  isTeamSearch: boolean;
}

export const getFilteredCustomers = async (
  props: GetFilteredCustomersProps
) => {
  const {
    auth,
    sendRequest,
    setLoadedItems,
    setFiltered,
    searchValue,
    filter1Active,
    filter2Active,
    filter1Value,
    filter2Value,
    setShowLoadMore,
    multiplier,
    setMultiplier,
    fetchId,
    isTeamSearch,
  } = props;

  const fetchType =
    fetchId === auth.userId || auth.signupPlan !== "ENTERPRISE"
      ? "USER"
      : fetchId === auth.managerId
      ? "MANAGER"
      : isTeamSearch
      ? "TEAM"
      : "VENDOR";

  try {
    const responseData: { customers: CustomerData[]; hasMoreItems: boolean } =
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/customers/filtered/${auth.userId}/${multiplier}`,
        "POST",
        JSON.stringify({
          fetchType,
          fetchId,
          searchValue,
          filter1Active,
          filter2Active,
          filter1Value,
          filter2Value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        false,
        "customers"
      );

    if (responseData && responseData.customers?.length > 0) {
      setLoadedItems((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.customers];
        } else {
          return responseData.customers;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) {
    console.log(err);
  }
};

// UPLOAD CUSTOMERS //////////////////////////////////////
//////////////////////////////////////////////////////////
interface UploadCustomersProps extends FetchProps {
  formState: FormHookDispState;
}

export const uploadCustomers = async (props: UploadCustomersProps) => {
  const { sendRequest, auth, formState } = props;

  const formData = new FormData();
  formData.append("file", formState.inputs.file.value as File);

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/customers/upload/${auth.userId}`,
      "POST",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) {
    console.log(err);
  }
};

// EDIT SELECTED CUSTOMERS OWNERS /////////////////
///////////////////////////////////////////////////
interface EditSelectedCustomersOwnersProps extends FetchProps {
  customersIds: string[];
  formState: FormHookDispState;
  setLoadedData: React.Dispatch<React.SetStateAction<CustomerData[]>>;
}

export const editSelectedCustomersOwners = async (
  props: EditSelectedCustomersOwnersProps
) => {
  const { sendRequest, auth, setLoadedData, customersIds, formState } = props;
  const ownersInput = formState?.inputs?.owners?.value as string[];
  const ownersEmails = ownersInput
    ?.map((data) => {
      const [first, ...rest] = data.split("-");
      const remainder = rest?.join("-")?.trim() || "";

      return remainder;
    })
    ?.filter((data) => {
      return !!data;
    }) as string[];
  let ownersIds =
    auth.vendors
      ?.filter((data) => {
        return ownersEmails?.includes?.(data?.email);
      })
      ?.map((data) => {
        return data?._id;
      }) || [];

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/customers/selected/owners`;
    await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ customersIds, owners: ownersIds }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    // setLoadedData((prevValues) => {
    //   prevValues = prevValues.map((item) => {
    //     if (customersIds.includes(item.id)) {
    //       item.owners = ownersIds;
    //     }
    //     return item;
    //   });

    //   return prevValues;
    // });
  } catch (err) {}
};

// EXPORT CUSTOMERS //////////////////////////////////////////
//////////////////////////////////////////////////////////
interface ExportCustomersProps extends FetchProps {
  formState: {timePeriod: TimePeriodData};
}

export const exportCustomers = async (props: ExportCustomersProps) => {
  const { sendRequest, auth, formState } = props;

  try {
    return await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/customers/export/${auth.userId}`,
        "POST",
        JSON.stringify(formState),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        true
    );
  } catch (err) {
    console.log(err);
  }
};