import { Fragment, useContext, useEffect, useState } from "react";

import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { AuthContext } from "../../../shared/context/authContext";
import { SettingsContext } from "../../../shared/context/settingsContext";

import "./Chats.scss";
import { ChatsOmnichannel } from "../../../shared/components/UIElements/ChatsOmnichannel";
import { CustomerData, GeneralUserData, ManagerData, UserData } from "../../../shared/data/types";
import { fetchGeneralUserData } from "../../api/homePageUserAPI";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { getPaginatedManagerVendors } from "../../../manager/api/managersAPI";
import { fetchCustomers } from "../../../customer/api/customersAPI";
import { ChatsOmnichannelWithFilters } from "../../../shared/components/UIElements/ChatsOmnichannelWithFilters";
import { CircularProgress } from "@material-ui/core";

interface ChatProps {

}

export default function Chats(props: ChatProps) {
  const { } = props;
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const settingsCtx = useContext(SettingsContext);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<CustomerData[]>(null);
  const [vendors, setVendors] = useState<UserData[]>(null);
  const [networkDataReceived, setNetworkDataReceived] = useState<boolean>(false);
  const [memory, setMemory] = useState<CustomerData>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [phones, setPhones] = useState<string[]>([]);

  useEffect(() => {
    fetchCustomers({
      sendRequest,
      auth,
      hidration: true,
      setNetworkDataReceived,
      setLoadedData,
      setShowLoadMore,
      multiplier: 0,
      setFirstLoad,
    });
  }, [sendRequest, auth]);

  useEffect(() => {
    sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/managers/vendors-for-manager/${auth.userId}/${auth.managerId}/`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      false
    ).then((response) => {
      const vendors: UserData[] = response.vendors;

      const options = vendors
        .map((vendor) => {
          return {
            id: vendor._id,
            txt: vendor.personalData.name,
          };
        })
        .sort(function (x, y) {
          return x.txt.localeCompare(y.txt);
        });

      setVendors(vendors);
    });
  }, [auth.managerId, auth.token, auth.userId, sendRequest]);

  useEffect(() => {
    if (!!loadedData) {
      const customerUser = loadedData[0];
      setMemory(customerUser);
    }
  }, [loadedData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);

    const phones = [];
    
    if (newValue !== 0) {
      const account = settingsCtx.wppAccounts[newValue-1];
      const phone = (account.phone.startsWith('55') || account.phone.startsWith('+55')) ? account.phone.replace(/[^\d]/g, "") : `55${account.phone.replace(/[^\d]/g, "")}`;

      phones.push(phone);
    }

    setPhones(phones);
  };

  const renderVendorTabs = () => {
    if (!settingsCtx.wppAccounts.length) return null;

    return settingsCtx.wppAccounts.map(account => {
      const tabLabel = (account.vendor && typeof account.vendor === "object") ? account?.vendor?.personalData.name : (account?.phone || "");

      return (
        <Tab label={tabLabel} id={`tab-${account._id}`} aria-labelledby={`tabpanel-${account._id}`} />
      );
    });
  };

  return (
    <Fragment>
      <div className="chats">
        <Paper elevation={3}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Todos" id={`tab-all`} />
            {renderVendorTabs()}
          </Tabs>
          <div
            className="chats__tabpanel"
            role="tabpanel"
            id={`tabpanel-all`}
          >
            {memory ? (
              <ChatsOmnichannelWithFilters
                auth={auth}
                customers={loadedData}
                settingsCtx={settingsCtx}
                withPhones={phones}
              />
            ) : (
              <div className="chats__tabpanel__loader">
                <CircularProgress size={50} />
              </div>
            )}
          </div>
        </Paper>
      </div>
    </Fragment>
  );
}
